import { Box, Typography } from "@mui/material";
import { SurveyStatDto } from "../../../../types/report";
import { Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

type SurveyStatChartProps = {
  data?: SurveyStatDto[];
};
export const SurveyStatChart: React.FC<SurveyStatChartProps> = ({ data }) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const groupedData = data?.reduce<Record<string, { month: string; respondent: number }>>((acc, d) => {
    const key = `${d._id.year}-${d._id.month}`;
    if (!acc[key]) {
      acc[key] = {
        month: `${monthNames[d._id.month - 1]} ${d._id.year}`,
        respondent: 0,
      };
    }
    acc[key].respondent += d.numberOfSurvey || 0;
    return acc;
  }, {});

  const chartData = Object.values(groupedData || {});

  return (
    <Box my={2}>
      <Typography variant="h6">Number of respondent</Typography>
      <Box my={2}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={150} height={40} data={chartData}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="respondent" fill="#8884d8" barSize={40} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
