import { useEffect, useState } from "react";
import { getSurveyToken } from "../../utils/apis/survey-api";
import { Survey } from "../../types/survey";
import { Display } from "./components/display";
import { useCookies } from "react-cookie";
import { saveActivity } from "../../utils/apis/activity-api";
import { ACTIVITY_TYPE, PROTERATE, PROTERATE_UUID } from "../../utils/apis/constant";
import { Box, Card, Divider, Grid2 as Grid, LinearProgress, Typography } from "@mui/material";
import { saveAnswer, saveResponse } from "../../utils/apis/response-api";

export const Portal: React.FC<{}> = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const [cookies, setCookie] = useCookies([PROTERATE, PROTERATE_UUID]);
  const [survey, setSurvey] = useState<Survey>();
  const [surveyComplete, setSurveyComplete] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers] = useState<any>([]);

  const onSurveySubmit = async (event: React.SyntheticEvent, value: number | string | null) => {
    event.preventDefault();

    const originUrl = window.parent.location.href;
    const responseId = cookies.proterate["responseId"];
    const uid = cookies.proterateUUID;

    if (survey?.question && currentQuestion === survey.question.length - 1) {
      setSurveyComplete(true);
    }

    answers.push({ questionId: survey?.question?.[currentQuestion].questionId[0]._id, responseValue: value });
    await saveActivity(survey?._id || "", cookies.proterateUUID, ACTIVITY_TYPE.ANSWERED, {
      url: originUrl,
      response: { questionId: survey?.question?.[currentQuestion].questionId[0]._id, responseValue: value },
    });

    await saveAnswer(
      survey?._id || "default-survey-id",
      uid || "default-id",
      survey?.question?.[currentQuestion].questionId[0]._id || "",
      value || ""
    );

    if (currentQuestion < (survey?.question?.length || 1) - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      await saveActivity(survey?._id || "", cookies.proterateUUID || "", ACTIVITY_TYPE.SUBMITTED, {
        url: originUrl,
        response: answers,
      });
      await saveResponse(responseId, answers);
      setCookie("proterate", { responseId: responseId, responsed: true }, { path: "/" });
      setTimeout(() => {
        window.parent.postMessage("closeSurvey", "*");
      }, 1000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const surveyResponse = await getSurveyToken(token || "");
      setSurvey(surveyResponse);
      await saveActivity(surveyResponse?._id || "", cookies.proterateUUID || "", "displayed");
    };

    fetchData();
  }, [cookies.proterateUUID, token]);
  return (
    <>
      <Grid container spacing={2} sx={{ p: 2, justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Grid size={{ xs: 12, sm: 6, md: 6 }} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Card variant="outlined" sx={{ p: 2, position: "relative" }}>
            <LinearProgress
              variant="determinate"
              value={(100 / (survey?.question?.length || 1)) * (currentQuestion + 1)}
              sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
            />
            {survey && (
              <Display
                survey={survey}
                currentQuestion={currentQuestion}
                onSurveySubmit={onSurveySubmit}
                surveyComplete={surveyComplete}
              />
            )}
            {currentQuestion === 0 && (
              <Box>
                <Divider />
                <Typography variant="body2" component={"div"} mt={1}>
                  We use cookies to improve your experience. By continuing, you agree to our{" "}
                  <a href="https://proterate.com/cookie-policy" target="_blank" rel="noreferrer">
                    Cookie Policy
                  </a>
                </Typography>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
