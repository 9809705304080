import { Box, Typography } from "@mui/material";
import { Survey } from "../../../types/survey";
import { SURVEY_TYPE } from "../../../utils/apis/constant";
import { CastOption } from "./csat";
import { NpsOption } from "./nps";
import { TextOption } from "./text";

type DisplayProps = {
  survey: Survey;
  currentQuestion: number;
  surveyComplete: boolean;
  onSurveySubmit: (event: React.SyntheticEvent, value: number | string | null) => void;
};

export const Display: React.FC<DisplayProps> = ({ survey, currentQuestion, surveyComplete, onSurveySubmit }) => {
  if (surveyComplete) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
        }}>
        <Box>
          <Typography variant="h3">🎉</Typography>
        </Box>
        <Box>
          <Typography variant="h5">Thank you for your response</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Box sx={{ padding: "0 16px 16px 0", minWidth: "300px" }}>
        <Typography variant="subtitle1">{survey?.question?.[currentQuestion].questionId[0].questionDisplay}</Typography>
      </Box>
      <Box>
        {survey?.question?.[currentQuestion].questionId[0].option.optionType === SURVEY_TYPE.NPS && (
          <NpsOption question={survey?.question?.[0]} onSurveySubmit={onSurveySubmit} />
        )}
        {survey?.question?.[currentQuestion].questionId[0].option.optionType === SURVEY_TYPE.CSAT && (
          <CastOption onSurveySubmit={onSurveySubmit} />
        )}
        {survey?.question?.[currentQuestion].questionId[0].option.optionType === SURVEY_TYPE.TEXT && (
          <TextOption question={survey?.question?.[0]} onSurveySubmit={onSurveySubmit} />
        )}
      </Box>
    </Box>
  );
};
