import { useForm } from "react-hook-form";
import { loginValidationSchema } from "./login-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginRequest } from "./types/login.types";
import { useMutation } from "@tanstack/react-query";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { PROTERATE_TOKEN, ROUTE } from "../../../../utils/apis/constant";
import { useCookies } from "react-cookie";

export const useLoginForm = () => {
  const navigator = useNavigate();
  const setCookie = useCookies([PROTERATE_TOKEN])[1];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    resolver: yupResolver(loginValidationSchema),
    mode: "onChange",
  });

  const {
    mutate,
    isPending,
    isError,
    error: apiError,
  } = useMutation({
    mutationFn: ({ email, password }: LoginRequest) => signInWithEmailAndPassword(auth, email, password),
    onSuccess: async (res) => {
      const token = await res.user.getIdToken(true);
      setCookie(PROTERATE_TOKEN, token);
      navigator(ROUTE.DASHBOARD);
    },
  });

  const onSubmit = (data: LoginRequest) => {
    mutate(data);
  };

  return { onSubmit, register, handleSubmit, errors, isPending, isError, apiError };
};
