import { useQuery } from "@tanstack/react-query";
import { getSurveyBySurveyId } from "../../../../utils/apis/survey-api";

export const useSurvey = (surveyId?: string) => {
  const {
    data: survey,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["survey", surveyId],
    queryFn: () => getSurveyBySurveyId({ surveyId }),
    enabled: !!surveyId,
  });

  return { survey, isLoading, error };
};
