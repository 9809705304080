import axios from "axios";
import { CORE_API_URL } from "../config/internal";
import { Cookies } from "react-cookie";
import { PROTERATE_TOKEN } from "./apis/constant";

const axiosInstance = axios.create({
  baseURL: CORE_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const cookies = new Cookies();
    const token = cookies.get(PROTERATE_TOKEN);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response data before returning it
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
