import { Box, Step, StepButton, Stepper, Button, Switch, FormControlLabel, Card } from "@mui/material";
import { useEffect, useState } from "react";
import CreateNewSurveyStep from "./steps/create-survey";
import { useParams } from "react-router-dom";
import EditSurveyStep from "./steps/edit-survey";
import TriggerSurvey from "./steps/trigger-survey";

import ReportSummary from "../report";
import { useSurvey } from "./hooks/use-survey";
import { useSurveyStatus } from "./hooks/use-survey-update-status";
import { useUpdateSurvey } from "./hooks/use-survey-update-survey";
import { Survey } from "../../../types/survey";

const steps = ["Survey information", "Design your survey", "Trigger criteria", "Response analysis"];

export const SurveyComponent = () => {
  const param = useParams();
  const query = new URLSearchParams(window.location.search);
  const tab = query.get("tab");
  const initialStep = tab ? parseInt(tab) - 1 : 0;
  const surveyId = param.surveyId;
  const [completed] = useState<{
    [k: number]: boolean;
  }>({});
  const [activeStep, setActiveStep] = useState(initialStep);
  const [isUpdate, setIsUpdate] = useState(false);

  const { survey } = useSurvey(surveyId);
  const { mutateSurveyStatus } = useSurveyStatus();
  const { mutateSurvey } = useUpdateSurvey();
  const [currrentSurvey, setCurrrentSurvey] = useState<Survey>({} as Survey);

  useEffect(() => setCurrrentSurvey(survey ?? ({} as Survey)), [survey]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = async () => {
    if (isUpdate) {
      mutateSurvey(currrentSurvey);
    }
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    const query = new URLSearchParams(window.location.search);
    query.set("tab", (newActiveStep + 1).toString());
    window.history.replaceState(null, "", `?${query.toString()}`);
    setActiveStep(newActiveStep);
    setIsUpdate(false);
  };

  const handleBack = () => {
    if (isUpdate) {
      mutateSurvey(currrentSurvey);
    }
    const query = new URLSearchParams(window.location.search);
    query.set("tab", activeStep.toString());
    window.history.replaceState(null, "", `?${query.toString()}`);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsUpdate(false);
  };

  const handleStep = (step: number) => () => {
    if (isUpdate) {
      mutateSurvey(currrentSurvey);
    }
    const query = new URLSearchParams(window.location.search);
    query.set("tab", (step + 1).toString());
    window.history.replaceState(null, "", `?${query.toString()}`);
    setActiveStep(step);
    setIsUpdate(false);
  };

  const onSurveyEnabledChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const surveyEnabled = e.target.checked as boolean;
    mutateSurveyStatus({ surveyId, status: surveyEnabled });
  };

  const handleOnUpdateSurvey = async (survey: Survey) => {
    setIsUpdate(true);
    setCurrrentSurvey(survey);
  };

  if (!survey) {
    return null;
  }

  return (
    <Box my={4} className="survey">
      <Box my={4} className="survey-container">
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end" mx={2}>
          <FormControlLabel
            control={<Switch checked={survey?.isActive} onChange={onSurveyEnabledChange} />}
            label="Eanble survey"
          />
        </Box>
        <Box m={{ xs: 0, md: 4, lg: 4, xl: 4 }} className="survey-container-content">
          <Card variant="outlined" sx={{ borderRadius: "15px", padding: 4 }}>
            <Box sx={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box my={4}>
              {activeStep === 0 && (
                <CreateNewSurveyStep initialSurvey={currrentSurvey} onUpdateSurvey={handleOnUpdateSurvey} />
              )}
              {activeStep === 1 && (
                <EditSurveyStep initialSurvey={currrentSurvey} onUpdateSurvey={handleOnUpdateSurvey} />
              )}
              {activeStep === 2 && (
                <TriggerSurvey initialSurvey={currrentSurvey} onUpdateSurvey={handleOnUpdateSurvey} />
              )}
              {activeStep === 3 && <ReportSummary initialSurvey={survey} />}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="outlined">
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} sx={{ mr: 1 }} variant="contained">
                Save and Continue
              </Button>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
