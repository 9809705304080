import React from "react";
import {
  Box,
  Card,
  CardHeader,
  Grid2 as Grid,
  CardActionArea,
  CardContent,
  Switch,
  Typography,
  Skeleton,
} from "@mui/material";
import { Survey } from "../../../../types/survey";
import AddIcon from "@mui/icons-material/Add";

import "./survey-list.scss";

interface SurveysProps {
  surveys: Survey[];
  isLoading: boolean;
  onClick: (id?: string) => void;
  onPlaceHolderCreate: () => void;
}

const Surveys: React.FC<SurveysProps> = ({ surveys, onClick, onPlaceHolderCreate, isLoading }) => {
  return (
    <Box className="survey-list">
      <Card variant="outlined" className="survey-list-containter">
        <Box m={2}>
          <Grid container spacing={2} className="survey-list-grid">
            {isLoading ? (
              <>
                <Skeleton variant="rectangular" width={486} height={170} />
                <Skeleton variant="rectangular" width={486} height={170} />
              </>
            ) : (
              <>
                {surveys.map((survey) => (
                  <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }} key={survey._id}>
                    <Card variant="outlined" className="survey-list-card">
                      <CardActionArea
                        onClick={() => {
                          onClick(survey?._id);
                        }}>
                        <Box>
                          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <CardHeader
                              title={survey.title || "Survey title"}
                              subheader={survey.description}
                              sx={{ minHeight: "88px", alignItems: "flex-start" }}
                            />
                            <Switch checked={survey.isActive} />
                          </Box>
                        </Box>
                        <CardContent>{survey?.creatorId?.name}</CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
                <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
                  <Card variant="outlined" className="survey-list-card-add-new">
                    <CardActionArea
                      className="survey-list-card-add-new-card-action"
                      onClick={onPlaceHolderCreate}
                      disabled={surveys.length >= 5}>
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <AddIcon sx={{ fontSize: "30px" }} />
                        <Typography variant="h6" fontWeight={"bold"} sx={{ textAlign: "center" }}>
                          Create a new survey
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
              </>
            )}
            {/* <CreateSurveyType /> */}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default Surveys;
