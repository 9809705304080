import { Container, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Effective Date: 11 Mar 2025
      </Typography>

      {sections.map((section) => (
        <>
          <Typography variant="h6">{section.title}</Typography>
          <Typography>{section.content}</Typography>
        </>
      ))}
    </Container>
  );
};

const sections = [
  {
    title: "Introduction",
    content:
      "Welcome to Proterate. These Terms and Conditions govern your use of our services, website, and related offerings. By accessing or using our platform, you agree to comply with these terms. If you do not agree, please refrain from using our services.",
  },
  {
    title: "Definitions",
    content:
      "- 'Proterate' refers to our platform, including all associated services and applications.\n- 'User' refers to anyone who accesses or uses Proterate.\n- 'Content' refers to any data, text, graphics, or other materials available through Proterate.",
  },
  {
    title: "Use of Services",
    content:
      "- You must be at least 18 years old to use our services.\n- You agree not to engage in any unlawful or unauthorized use of our platform.\n- We reserve the right to suspend or terminate accounts that violate these terms.",
  },
  {
    title: "User Accounts",
    content:
      "- Users must provide accurate and complete information when creating an account.\n- You are responsible for maintaining the confidentiality of your account credentials.\n- We are not liable for any unauthorized access to your account.",
  },
  {
    title: "Intellectual Property",
    content:
      "- All content and materials on Proterate are owned by us or our licensors.\n- You may not copy, distribute, or modify any part of Proterate without our written consent.",
  },
  {
    title: "Limitation of Liability",
    content:
      "- We are not liable for any indirect, incidental, or consequential damages arising from your use of Proterate.\n- We do not guarantee the availability, accuracy, or reliability of our platform.",
  },
  {
    title: "Privacy Policy",
    content:
      "- Your use of Proterate is also governed by our Privacy Policy.\n- We collect and process personal data in accordance with applicable laws.",
  },
  {
    title: "Modifications",
    content:
      "- We reserve the right to modify these Terms and Conditions at any time.\n- Continued use of Proterate after changes constitute acceptance of the updated terms.",
  },
  {
    title: "Termination",
    content:
      "- We may suspend or terminate your access if you breach these terms.\n- Users may terminate their account at any time.",
  },
  {
    title: "Governing Law",
    content:
      "- These terms are governed by the laws of Proterate.\n- Any disputes will be resolved in the courts of Proterate.",
  },
  {
    title: "Contact Us",
    content: "For any questions regarding these Terms and Conditions, please contact us at Proterate.",
  },
];

export default TermsAndConditions;
