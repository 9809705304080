import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSurveyStatus } from "../../../../utils/apis/survey-api";
import { useSnackbar } from "../../../../libs/snackbar-provider";

export const useSurveyStatus = () => {
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const {
    mutate: mutateSurveyStatus,
    isPending: isSurveyStatusLoading,
    error,
  } = useMutation({
    mutationFn: updateSurveyStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["survey"] });
      showSnackbar("Survey status updated", "success");
    },
    onError: (error) => {
      showSnackbar(error.message, "error");
    },
  });

  return { mutateSurveyStatus, isSurveyStatusLoading, error };
};
