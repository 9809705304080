import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

type NPSMeterProps = {
  value: number;
};

export const NPSMeter: React.FC<NPSMeterProps> = ({ value }) => {
  return (
    <>
      <Gauge
        width={300}
        height={300}
        value={value}
        sx={{
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 40,
            transform: "translate(0px, 0px)",
          },
          [`& .MuiGauge-valueArc`]: {
            fill: value <= 25 ? "#d32f2f" : value <= 50 ? "#f57c00" : value <= 75 ? "#388e3c" : "#1976d2",
          },
        }}
        startAngle={-110}
        endAngle={110}
        text={({ value, valueMax }) => `${value} / ${valueMax}`}
      />
    </>
  );
};
