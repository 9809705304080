import { useForm } from "react-hook-form";
import { signUpValidationSchema } from "./sign-up-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignupRequest } from "./types/sign-up.types";
import { useMutation } from "@tanstack/react-query";
import { sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../../utils/apis/constant";
import { postCreateUser } from "../../../../utils/apis/user-api";
import { REDIRECT_URL } from "../../../../config/internal";

export const useSignUpForm = () => {
  const navigator = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignupRequest>({
    resolver: yupResolver(signUpValidationSchema),
    mode: "onChange",
  });

  const {
    mutate,
    isPending,
    isError,
    error: apiError,
  } = useMutation({
    mutationFn: ({ email, password }: SignupRequest) =>
      postCreateUser({
        email,
        password,
        provider: "password",
        role: "admin",
      }),
    onSuccess: async (res, variables) => {
      if (res.data) {
        const { password } = variables;
        const user = await signInWithEmailAndPassword(auth, res.data.email, password);

        if (!user.user.emailVerified) {
          await sendEmailVerification(user.user, { url: REDIRECT_URL });
          navigator(ROUTE.VERIFY_EMAIL);
        }
      }
    },
  });

  const onSubmit = (data: SignupRequest) => {
    mutate(data);
  };

  return { onSubmit, register, handleSubmit, errors, isPending, isError, apiError, isValid };
};
