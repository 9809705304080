import { useState } from "react";
import { Question } from "../../../types/survey";
import { Button, Grid2 as Grid, TextField } from "@mui/material";

type TextOptionProps = {
  question: Question;
  onSurveySubmit: (event: React.SyntheticEvent, value: number | string | null) => void;
};

export const TextOption: React.FC<TextOptionProps> = ({ question, onSurveySubmit }) => {
  const [inputValue, setInputValue] = useState("");

  const handleOnSumit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (inputValue.trim()) {
      onSurveySubmit(event, inputValue);
      setInputValue("");
    }
  };

  return (
    <>
      <Grid spacing={2} display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center" container>
        <TextField
          size="small"
          fullWidth
          multiline
          maxRows={5}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type your response here..."
        />
        <Button variant="contained" onClick={handleOnSumit}>
          Submit
        </Button>
      </Grid>
    </>
  );
};
