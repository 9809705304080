import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardHeader, Grid2 as Grid, Pagination, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { getAccumurate, getReport, getSurveyStat } from "../../../utils/apis/report-api";
import { AccumurateDto, Report, SurveyStatDto } from "../../../types/report";
import { Survey } from "../../../types/survey";
import { SurveyStatChart } from "./components/survey-stat-chart";
import { CSATMeter } from "./components/csat-meter";
import { NPSMeter } from "./components/nps-meter";

interface ReportProps {
  initialSurvey: Survey;
}

const ReportSummary: React.FC<ReportProps> = ({ initialSurvey }) => {
  const param = useParams();
  const surveyId = param.surveyId;

  const MAX_SCALE_CSAT = 5;
  const MAX_SCALE_NPS = 10;

  const [report, setReport] = useState<Report>();
  const [accumurate, setAccumurate] = useState<AccumurateDto>();
  const [surveyStat, setSurveyStat] = useState<SurveyStatDto[]>();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchDate = async () => {
      const report = await getReport(surveyId, page, 10);
      setReport(report);
    };

    fetchDate();
  }, [surveyId, page]);

  useEffect(() => {
    const fetchDate = async () => {
      const accumurateData = await getAccumurate(surveyId);
      const surveyStatData = await getSurveyStat(surveyId);
      setAccumurate(accumurateData);
      setSurveyStat(surveyStatData);
    };

    fetchDate();
  }, [surveyId, page]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box my={4}>
      <SurveyStatChart data={surveyStat} />
      <Box my={2}>
        <Typography variant="h6">CSAT and NPS scores</Typography>
        <Box my={2}>
          <Grid container spacing={2} display="flex" justifyContent="space-between">
            {accumurate?.totalScore.map((item) => {
              if (item.question.option.optionType === "csat") {
                return (
                  <>
                    <Typography variant="body1"> {item.question.questionDisplay}</Typography>
                    <Grid size={{ xs: 12, md: 12, lg: 12 }} display="flex" justifyContent="center">
                      <CSATMeter
                        value={Math.round(
                          (item.totalResponseValue || 0) * (100 / ((item.numberOfSurvey || 1) * MAX_SCALE_CSAT))
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              return (
                <>
                  <Typography variant="body1"> {item.question.questionDisplay}</Typography>
                  <Grid size={{ xs: 12, md: 12, lg: 12 }} display="flex" justifyContent="center">
                    <NPSMeter
                      value={Math.round(
                        (item.totalResponseValue || 0) * (100 / ((item.numberOfSurvey || 1) * MAX_SCALE_NPS))
                      )}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box my={2}>
        <Box my={2}>
          <Typography variant="h6">Response values</Typography>
        </Box>
        <Grid container spacing={2}>
          {report?.responses.map((item) => {
            return (
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <Card variant="outlined">
                  <CardHeader subheader={`Respondent ID : ${item._id || "Anonymous"}`} />
                  <CardContent>
                    {item.responses.map((response) => {
                      return (
                        <Box>
                          <Typography variant="body1">{response.question.questionDisplay}</Typography>
                          <Typography variant="body1" fontWeight={800}>
                            {response.responseValue}
                          </Typography>
                        </Box>
                      );
                    })}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Box my={2} display="flex" alignItems="center" justifyContent="center">
          <Pagination count={Math.ceil((report?.totalCount || 10) / 10)} page={page} onChange={handlePageChange} />
        </Box>
      </Box>
    </Box>
  );
};

export default ReportSummary;
