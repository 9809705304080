import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/container/home";
import SurveyDashboard from "./pages/container/dashboard";
import { SurveyComponent } from "./pages/container/survey";
import { Setting } from "./pages/container/settings";
import { VerifyEmail } from "./pages/container/auth/components/verify-email";
import { HowTo } from "./pages/container/how-to";
import { SurveyWidget } from "./sdk/survey/survey";
import ProterateLayout from "./pages/container/layout";
import { Portal } from "./sdk/portal/portal";
import { CookiePolicy } from "./pages/container/policy/cookie-policy";
import { Login } from "./pages/container/auth/login";
import { Signup } from "./pages/container/auth/signup";
import TermsAndConditions from "./pages/container/policy/term-and-condition";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/signup" element={<Signup />} />
        <Route path="/widget" element={<SurveyWidget />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/term-and-condition" element={<TermsAndConditions />} />
        <Route path="/dashboard" element={<ProterateLayout />}>
          <Route index element={<SurveyDashboard />} />
        </Route>
        <Route path="/setting" element={<ProterateLayout />}>
          <Route index element={<Setting />} />
        </Route>
        <Route path="/how-to" element={<ProterateLayout />}>
          <Route index element={<HowTo />} />
        </Route>
        <Route path="/survey/:surveyId" element={<ProterateLayout />}>
          <Route index element={<SurveyComponent />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
