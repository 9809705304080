import React from "react";
import { Box, Container, Grid2 as Grid, Link, Typography } from "@mui/material";
interface AuthLayoutProps {
  children: React.ReactNode;
}
export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <>
      <Container>
        <Box display="flex" alignItems="center" height="100vh" my={4}>
          <Grid container spacing={2} display="flex" flexDirection="row">
            <Grid
              size={{ lg: 6, md: 6, xs: 12 }}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              position="relative">
              <img src="/assets/auth-state.jpg" alt="Auth page" width="100%" />
              <Typography variant="overline" fontSize="10px" fontWeight={1} position="absolute" right={0} bottom={0}>
                <Link color="black" href="http://www.freepik.com" underline="none" target="_blank" rel="noreferrer">
                  Designed by Freepik
                </Link>
              </Typography>
            </Grid>
            <Grid size={{ lg: 6, md: 6, xs: 12 }}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                {children}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
