import React from "react";
import { Alert, Box, Card, CardContent, Grid2 as Grid, Typography } from "@mui/material";
import Surveys from "../survey/list/survey-list";
import { useNavigate } from "react-router-dom";

import { getUserApi } from "../../../utils/apis/user-api";
import { createSurvey, getSurveysByUserId } from "../../../utils/apis/survey-api";
import { ROUTE } from "../../../utils/apis/constant";

import "./dashboard.scss";
import { useQuery } from "@tanstack/react-query";

const SurveyDashboard: React.FC = () => {
  const navigator = useNavigate();

  const { data: userId, error: errorUser, isLoading } = useQuery({ queryKey: ["user"], queryFn: getUserApi });

  const {
    data: surveys,
    error: errorSurveys,
    isLoading: isLoadingSurveys,
  } = useQuery({
    queryKey: ["surveys", userId],
    queryFn: () => getSurveysByUserId(userId?.data?.uid || ""),
    enabled: !!userId,
  });

  const createPlaceHolder = async () => {
    const survey = await createSurvey(userId?.data?.uid || "", "embedded");
    const redirectUrl = `${ROUTE.SURVEY}/${survey._id}`;
    navigator(redirectUrl);
  };

  const handleOnClick = async (surveyId?: string) => {
    const redirectUrl = `${ROUTE.SURVEY}/${surveyId}`;

    navigator(redirectUrl);
  };

  return (
    <Box className="dashboard" m={4}>
      <Box my={4}>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 12, lg: 3, xl: 3 }}>
              <Card variant="outlined" className="dashboard-card">
                <CardContent>
                  <Typography variant="body1">Total surveys</Typography>
                  <Typography variant="h6" fontWeight={"bold"} component={"div"} sx={{ textAlign: "center" }}>
                    {surveys?.surveyList.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 12, md: 12, lg: 3, xl: 3 }}>
              <Card variant="outlined" className="dashboard-card">
                <CardContent>
                  <Typography variant="body1">Total responses</Typography>
                  <Typography variant="h6" fontWeight={"bold"} component={"div"} sx={{ textAlign: "center" }}>
                    {surveys?.totalResponse}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 12, md: 12, lg: 3, xl: 3 }}>
              <Card variant="outlined" className="dashboard-card">
                <CardContent>
                  <Typography variant="body1">Active survey</Typography>
                  <Typography variant="h6" fontWeight={"bold"} component={"div"} sx={{ textAlign: "center" }}>
                    {surveys?.activeSurvey}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid size={{ xs: 12, md: 12, lg: 3, xl: 3 }}>
              <Card variant="outlined" className="dashboard-card">
                <CardContent>
                  <Typography variant="body1">Survey display</Typography>
                  <Typography variant="h6" fontWeight={"bold"} component={"div"} sx={{ textAlign: "center" }}>
                    {surveys?.totalViews}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box my={4}>
          {errorSurveys && <Alert severity="error">{errorSurveys?.message}</Alert>}
          {errorUser && <Alert severity="error">{errorUser?.message}</Alert>}
          <Surveys
            surveys={surveys?.surveyList || []}
            onClick={handleOnClick}
            onPlaceHolderCreate={createPlaceHolder}
            key="survey-list"
            isLoading={isLoadingSurveys || isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyDashboard;
