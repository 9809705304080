import { Box, Button, Fade, Paper, Stack, Typography, Unstable_TrapFocus as TrapFocus } from "@mui/material";
import { useEffect, useState } from "react";
import { PROTERATE_ACCEPT_COOKIE } from "../../../../utils/apis/constant";
import { useCookies } from "react-cookie";

export const CookiesBanner = () => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const [cookies, setCookie] = useCookies([PROTERATE_ACCEPT_COOKIE]);

  const acceptCookie = () => {
    setCookie(PROTERATE_ACCEPT_COOKIE, true, { path: "/" });
    setBannerOpen(false);
  };

  useEffect(() => {
    if (!cookies[PROTERATE_ACCEPT_COOKIE]) {
      setBannerOpen(true);
    }
  }, [cookies]);

  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={bannerOpen}>
        <Paper
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          variant="outlined"
          tabIndex={1500}
          sx={{
            position: "fixed",
            bottom: { xs: 0, md: 30 },
            left: { xs: 0, md: 50 },
            right: { xs: 0, md: 50 },
            m: { xs: 0, md: "0 auto" },
            p: 2,
            borderWidth: 1,
            borderRadius: 5,
            width: { xs: "unset", md: "800px" },
          }}>
          <Stack direction={{ xs: "column", sm: "row" }} sx={{ justifyContent: "space-between", gap: 2 }}>
            <Box sx={{ flexShrink: 1, alignSelf: { xs: "flex-start", sm: "center" } }}>
              <Typography sx={{ fontWeight: "bold" }}>We use Cookie</Typography>
              <Typography variant="body2">
                We use cookies to improve your experience, deliver personalized content and analyze our traffic.
              </Typography>
            </Box>
            <Stack
              direction={{
                xs: "row-reverse",
                sm: "row",
              }}
              sx={{
                gap: 2,
                flexShrink: 0,
                alignSelf: { xs: "flex-end", sm: "center" },
              }}>
              <Button size="small" onClick={acceptCookie} variant="contained">
                Allow all
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
};
