import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid2 as Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Survey } from "../../../../types/survey";
import { createQuestion, deleteQuestion, getSurveyBySurveyId, updateSurvey } from "../../../../utils/apis/survey-api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { NpsOption } from "../../../../sdk/survey/components/nps";
import { SURVEY_TYPE } from "../../../../utils/apis/constant";
import { CastOption } from "../../../../sdk/survey/components/csat";
import { TextOption } from "../../../../sdk/survey/components/text";
import { AddNewQuestionDialog } from "./add-new-question";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface EditSurveyStepProps {
  initialSurvey: Survey;
  onUpdateSurvey: (survey: Survey) => void;
}

const EditSurveyStep: React.FC<EditSurveyStepProps> = ({ initialSurvey, onUpdateSurvey }) => {
  const [variant, setVariant] = useState(initialSurvey.displayOption || "1");
  const [activeIndex, setActiveIndex] = useState(0);
  const [openAddNewQuestionDialog, setOpenAddNewQuestionDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const onSurveyQuestionChange = (value: string, index: number) => {
    const questionDisplay = value;
    let question = initialSurvey.question;
    if (question && question[index] && question[index].questionId && question[index].questionId[0]) {
      question[index].questionId[0].questionDisplay = questionDisplay;
    }
    onUpdateSurvey({ ...initialSurvey, question });
  };

  const handleVariant = (event: SelectChangeEvent) => {
    setVariant(event.target.value as string);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    let question = initialSurvey.question;
    const [movedItem] = question?.splice(result.source.index, 1) || [];
    question?.splice(result.destination.index, 0, movedItem);
    question?.forEach((item, index) => {
      item.step = index;
    });
    onUpdateSurvey({ ...initialSurvey, question });
  };

  const handleOnQuestionFocus = (index: number) => {
    setActiveIndex(index);
  };

  const mutationCreateQuestion = useMutation({
    mutationFn: createQuestion,
    onSuccess: async () => {
      await getSurveyBySurveyId({ surveyId: initialSurvey._id });
      queryClient.invalidateQueries({ queryKey: ["survey", initialSurvey._id] });
    },
  });

  const handleOnCreateQuestion = async (question: string, questionType: string) => {
    mutationCreateQuestion.mutate({
      question: question,
      questionType,
      surveyId: initialSurvey?._id,
      step: initialSurvey?.question?.length,
    });
  };

  const handleOpenAddNewQuestionDialog = () => {
    setOpenAddNewQuestionDialog(true);
  };

  const handleCloseAddNewQuestionDialog = () => {
    setOpenAddNewQuestionDialog(false);
  };

  const mutationDeleteQuestion = useMutation({
    mutationFn: deleteQuestion,
    onSuccess: async () => {
      await getSurveyBySurveyId({ surveyId: initialSurvey._id });
      queryClient.invalidateQueries({ queryKey: ["survey", initialSurvey._id] });
    },
  });

  const handleOnDeleteQuestion = async (questionId: string) => {
    mutationDeleteQuestion.mutate({
      surveyId: initialSurvey._id,
      questionId,
    });
  };

  const handleClickOpen = (questionId: string) => {
    setDeleteIndex(questionId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      handleOnDeleteQuestion(deleteIndex);
    }
    handleClose();
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Display option</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={variant}
          label="Display option"
          onChange={handleVariant}>
          <MenuItem value="1">Center</MenuItem>
          <MenuItem value="2">Right corner</MenuItem>
        </Select>
      </FormControl>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6, lg: 6 }} my={2}>
          <Card variant="outlined" sx={{ height: "100%", maxHeight: "480px", padding: "10px", overflow: "auto" }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="accordionList">
                {(provided: DroppableProvided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {initialSurvey.question?.map((item, index) => (
                      <Draggable key={item.questionId[0]._id} draggableId={item.questionId[0]._id} index={index}>
                        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              marginBottom: 1,
                              backgroundColor: snapshot.isDragging ? "#f0f0f0" : "white",
                              borderRadius: "8px",
                              boxShadow: snapshot.isDragging ? "0 4px 6px rgba(0,0,0,0.1)" : "none",
                            }}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header">
                                <Icon>
                                  <DragIndicatorIcon />
                                </Icon>
                                <Typography component="span">Question {index + 1}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={2}>
                                  <TextField
                                    label="Display"
                                    value={item.questionId?.[0]?.questionDisplay}
                                    onChange={(e) => onSurveyQuestionChange(e.target.value, index)}
                                    onFocus={() => handleOnQuestionFocus(index)}
                                    fullWidth
                                    required
                                  />
                                  <TextField
                                    value={item.questionId?.[0]?.option.optionType}
                                    label="Type"
                                    fullWidth
                                    disabled
                                  />
                                </Grid>
                              </AccordionDetails>
                              <AccordionActions>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => handleClickOpen(item.questionId[0]._id)}
                                  disabled={initialSurvey.question?.length === 1}>
                                  Delete
                                </Button>
                              </AccordionActions>
                            </Accordion>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleOpenAddNewQuestionDialog}
              loading={mutationCreateQuestion.isPending}
              loadingPosition="start">
              Add
            </Button>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
          {variant === "1" && (
            <Box
              my={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="500px"
              sx={{ background: "rgba(0, 0, 0, 0.5)" }}>
              <Card sx={{ width: "400px", padding: "16px", transform: "scale(0.6)" }}>
                <Typography variant="h6">
                  {initialSurvey.question?.[activeIndex]?.questionId?.[0]?.questionDisplay}
                </Typography>
                <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
                  {initialSurvey?.question?.[activeIndex].questionId[0].option.optionType === SURVEY_TYPE.NPS && (
                    <NpsOption question={initialSurvey?.question?.[0]} onSurveySubmit={() => {}} />
                  )}
                  {initialSurvey?.question?.[activeIndex].questionId[0].option.optionType === SURVEY_TYPE.CSAT && (
                    <CastOption onSurveySubmit={() => {}} />
                  )}
                  {initialSurvey?.question?.[activeIndex].questionId[0].option.optionType === SURVEY_TYPE.TEXT && (
                    <TextOption question={initialSurvey?.question?.[0]} onSurveySubmit={() => {}} />
                  )}
                </DialogContent>
              </Card>
            </Box>
          )}
          {variant === "2" && (
            <Box
              my={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="500px"
              position="relative"
              sx={{ background: "rgba(0, 0, 0, 0.5)" }}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                  padding: "16px",
                  transform: "scale(0.6)",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  transformOrigin: "bottom right",
                }}>
                <Typography>{initialSurvey.question?.[activeIndex]?.questionId?.[0]?.questionDisplay}</Typography>
                <DialogContent>
                  {initialSurvey?.question?.[activeIndex].questionId[0].option.optionType === SURVEY_TYPE.NPS && (
                    <NpsOption question={initialSurvey?.question?.[0]} onSurveySubmit={() => {}} />
                  )}
                  {initialSurvey?.question?.[activeIndex].questionId[0].option.optionType === SURVEY_TYPE.CSAT && (
                    <CastOption onSurveySubmit={() => {}} />
                  )}
                  {initialSurvey?.question?.[activeIndex].questionId[0].option.optionType === SURVEY_TYPE.TEXT && (
                    <TextOption question={initialSurvey?.question?.[0]} onSurveySubmit={() => {}} />
                  )}
                </DialogContent>
              </Card>
            </Box>
          )}
        </Grid>
      </Grid>
      <AddNewQuestionDialog
        open={openAddNewQuestionDialog}
        handleClose={handleCloseAddNewQuestionDialog}
        handleCreateNewQuesetion={handleOnCreateQuestion}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this question?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditSurveyStep;
