import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { User } from "../../../types/auth";
import { getUserApi } from "../../../utils/apis/user-api";

export const HowTo: React.FC = () => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const getUserData = async () => {
      const userRes = await getUserApi();
      setUser(userRes.data);
    };

    getUserData();
  }, []);

  const embedScript = `
      <script src="https://cdn.proterate.com/embed/proterate-sdk.js" workspace-id="${user?.uid || "YOUR-WORKSPACE-ID"}"></script>
  `;

  return (
    <Container>
      <Box sx={{ padding: 4 }}>
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, margin: "auto" }}>
          <Typography variant="h4" gutterBottom>
            How to Embed Our Survey
          </Typography>
          <Typography variant="body1" gutterBottom>
            Copy the following script and paste it into your website's HTML where you want the survey to appear:
          </Typography>
          <Box
            component="pre"
            sx={{
              backgroundColor: "#f5f5f5",
              padding: 2,
              borderRadius: 1,
              overflow: "auto",
              marginBottom: 2,
              fontFamily: "monospace",
              whiteSpace: "pre-wrap",
            }}>
            {embedScript}
          </Box>
          <CopyToClipboard text={embedScript}>
            <Button variant="contained" color="primary" fullWidth>
              Copy to Clipboard
            </Button>
          </CopyToClipboard>
          <Box mt={4}>
            <Typography variant="h6">Preview Instructions</Typography>
            <Typography variant="body2">
              Make sure to replace <code>YOUR-WORKSPACE-ID</code> with the ID of your specific workspace. You can find
              your workspace id in setting page.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
