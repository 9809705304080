import {
  Box,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import TopicIcon from "@mui/icons-material/Topic";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";

import "./proterate-layout.scss";
import { useEffect, useState } from "react";
import { getUserApi } from "../../../utils/apis/user-api";
import { PROTERATE_TOKEN, ROUTE } from "../../../utils/apis/constant";
import { useCookies } from "react-cookie";
import { signOut } from "firebase/auth";
import { auth } from "../../../config/firebase";
import { stringAvatar } from "../../../utils/function/avatar";

const drawerWidth = 300;

const ProterateLayout: React.FC = () => {
  const navigator = useNavigate();
  const pathName = window.location.pathname;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([PROTERATE_TOKEN]);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserLogout = async () => {
    await signOut(auth);
    removeCookie(PROTERATE_TOKEN);
    navigator(ROUTE.LOGIN);
  };

  const handleSetting = () => {
    handleCloseUserMenu();
    navigator(ROUTE.SETTING);
  };

  useEffect(() => {
    const getUserData = async () => {
      const user = await getUserApi();
      setName(`${user?.data?.firstName} ${user?.data?.lastName}`);
      setPhoto(user?.data?.photoUrl || "");
    };

    getUserData();
  }, []);

  return (
    <Box className="proterate-layout">
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
        }}
        className="proterate-layout-drawer">
        <Box sx={{ overflow: "auto" }} className="proterate-layout-drawer-containter">
          <Toolbar className="proterate-layout-drawer-containter-toolbar">
            <Box>
              <img src="/logo.png" alt="Proterate logo" height={60} />
            </Box>
            <Typography
              variant="body1"
              noWrap
              component="a"
              href="/"
              sx={{
                m: 0,
                p: 0,
                letterSpacing: ".3rem",
                fontFamily: "monospace",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}>
              Proterate
            </Typography>
          </Toolbar>
          <Divider className="proterate-layout-drawer-divider" />
          <List>
            <ListItem key={"Dashboard"} disablePadding className="proterate-layout-drawer-containter-list-item">
              <ListItemButton
                onClick={() => navigator(ROUTE.DASHBOARD)}
                className={pathName === ROUTE.DASHBOARD ? "active" : ""}>
                <ListItemIcon className="proterate-layout-drawer-containter-list-icon">
                  {pathName === ROUTE.DASHBOARD ? <SpaceDashboardOutlinedIcon /> : <SpaceDashboardIcon />}
                </ListItemIcon>
                <ListItemText className="proterate-layout-drawer-containter-list-text" primary={"Dashboard"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider className="proterate-layout-drawer-divider" />
          <List>
            <ListItem key={"Documents"} disablePadding className="proterate-layout-drawer-containter-list-item">
              <ListItemButton
                onClick={() => navigator(ROUTE.HOWTO)}
                className={pathName === ROUTE.HOWTO ? "active" : ""}>
                <ListItemIcon className="proterate-layout-drawer-containter-list-icon">
                  {pathName === ROUTE.HOWTO ? <TopicOutlinedIcon /> : <TopicIcon />}
                </ListItemIcon>
                <ListItemText className="proterate-layout-drawer-containter-list-text" primary={"Documents"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"Setting"} disablePadding className="proterate-layout-drawer-containter-list-item">
              <ListItemButton
                onClick={() => navigator(ROUTE.SETTING)}
                className={pathName === ROUTE.SETTING ? "active" : ""}>
                <ListItemIcon className="proterate-layout-drawer-containter-list-icon">
                  {pathName === ROUTE.SETTING ? <SettingsOutlinedIcon /> : <SettingsIcon />}
                </ListItemIcon>
                <ListItemText className="proterate-layout-drawer-containter-list-text" primary={"Setting"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" className="proterate-layout-outlet" mb={4}>
        <Toolbar variant="dense" className="proterate-layout-drawer-containter-appbar">
          <Box></Box>
          <Box>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src={photo} {...stringAvatar(name)} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <MenuItem key="logout" onClick={handleSetting}>
                <Typography sx={{ textAlign: "center" }}>setting</Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={handleUserLogout}>
                <Typography sx={{ textAlign: "center" }}>logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <Outlet />
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
        className="proterate-layout-bottom-navigation">
        <BottomNavigation showLabels>
          <BottomNavigationAction
            className={pathName === ROUTE.DASHBOARD ? "active" : ""}
            label="Dashboard"
            onClick={() => navigator(ROUTE.DASHBOARD)}
            icon={pathName === ROUTE.DASHBOARD ? <SpaceDashboardOutlinedIcon /> : <SpaceDashboardIcon />}
          />
          <BottomNavigationAction
            label="Documents"
            onClick={() => navigator(ROUTE.HOWTO)}
            icon={pathName === ROUTE.HOWTO ? <TopicOutlinedIcon /> : <TopicIcon />}
            className={pathName === ROUTE.HOWTO ? "active" : ""}
          />
          <BottomNavigationAction
            label="Setting"
            onClick={() => navigator(ROUTE.SETTING)}
            icon={pathName === ROUTE.SETTING ? <SettingsOutlinedIcon /> : <SettingsIcon />}
            className={pathName === ROUTE.SETTING ? "active" : ""}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default ProterateLayout;
