import React, { useEffect, useState } from "react";
import { Box, Button, Grid2 as Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Survey } from "../../../../types/survey";
import { updateSurvey } from "../../../../utils/apis/survey-api";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BASE_URL } from "../../../../config/internal";
import CopyToClipboard from "react-copy-to-clipboard";

export interface TriggerSurveyProps {
  initialSurvey: Survey;

  onUpdateSurvey: (survey: Survey) => void;
}

const TriggerSurvey: React.FC<TriggerSurveyProps> = ({ initialSurvey, onUpdateSurvey }) => {
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const handleClickOpen = (index: number) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      handleDeleteHookUrl(deleteIndex);
    }
    handleClose();
  };

  const onSurveyHookUrlChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const hookUrl = e.target.value;
    let trigger_criteria = initialSurvey?.triggerCriteria;
    if (trigger_criteria && trigger_criteria.length < 1) {
      trigger_criteria.push({ hookUrl: "", pattern: "exact" });
    }

    if (trigger_criteria && trigger_criteria[index]) {
      trigger_criteria[index].hookUrl = hookUrl;
    }
    onUpdateSurvey({ ...initialSurvey, triggerCriteria: trigger_criteria });
  };

  const handleAddHookUrl = () => {
    initialSurvey?.triggerCriteria?.push({ hookUrl: "", pattern: "exact" });
    onUpdateSurvey({ ...initialSurvey });
  };

  const handleDeleteHookUrl = (index: number) => {
    initialSurvey?.triggerCriteria?.splice(index, 1);
    onUpdateSurvey({ ...initialSurvey });
  };

  return (
    <Box>
      <Box my={2}>
        <Box my={2}>
          <Typography variant="h6">Trigger criteria</Typography>
        </Box>
        <Box my={2}>
          <Grid size={12} sx={{ maxWidth: "md" }}>
            <TextField
              label="Portal website url"
              value={`${BASE_URL}/portal?token=${initialSurvey.surveyToken}`}
              fullWidth
              helperText="Copy this url and paste it in your portal website to trigger the survey"
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">exact</InputAdornment>,
                  endAdornment: (
                    <CopyToClipboard text={`${BASE_URL}/portal?token=${initialSurvey.surveyToken}`}>
                      <IconButton color="error">
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  ),
                },
              }}
            />
          </Grid>
        </Box>
        <Typography variant="h6">Embedded trigger criteria</Typography>
        <Box my={2}>
          <Grid container spacing={2} direction={"column"}>
            {initialSurvey?.triggerCriteria?.map((criteria, index) => (
              <Grid size={12} sx={{ maxWidth: "md" }}>
                <TextField
                  label="url"
                  placeholder="https://example.com"
                  value={criteria.hookUrl}
                  onChange={(e) => onSurveyHookUrlChange(e, index)}
                  fullWidth
                  required
                  slotProps={{
                    input: {
                      startAdornment: <InputAdornment position="start">exact</InputAdornment>,
                      endAdornment: (
                        <IconButton onClick={() => handleClickOpen(index)} color="error">
                          <DeleteForeverIcon />
                        </IconButton>
                      ),
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <Button variant="text" onClick={handleAddHookUrl}>
            Add more
          </Button>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this hook URL?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default TriggerSurvey;
