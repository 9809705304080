import React from "react";
import { Box, TextField } from "@mui/material";
import { Survey } from "../../../../types/survey";

interface CreateNewSurveyStepProps {
  initialSurvey: Survey;
  onUpdateSurvey: (survey: Survey) => void;
}

const CreateNewSurveyStep: React.FC<CreateNewSurveyStepProps> = ({ initialSurvey, onUpdateSurvey }) => {
  const handleTitleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value as string;
    onUpdateSurvey({ ...initialSurvey, title });
  };

  const handleDescriptionChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const description = e.target.value as string;
    onUpdateSurvey({ ...initialSurvey, description });
  };

  return (
    <Box>
      <Box my={2}>
        <TextField label="Survey title" value={initialSurvey?.title} onChange={handleTitleChange} fullWidth required />
      </Box>
      <Box my={2}>
        <TextField
          label="Description"
          value={initialSurvey?.description}
          onChange={handleDescriptionChange}
          fullWidth
          required
          multiline
          rows={3}
        />
      </Box>
    </Box>
  );
};

export default CreateNewSurveyStep;
