import React from "react";
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItem,
  Button,
  CardActions,
  Grid2 as Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Check from "@mui/icons-material/Check";
import { ROUTE } from "../../../../utils/apis/constant";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const PricingSection: React.FC = () => {
  const navigator = useNavigate();

  const navigateToAccount = () => {
    navigator(ROUTE.LOGIN);
  };

  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        py: 16,
      }}>
      <Container>
        <Typography variant="h3" textAlign="center" gutterBottom>
          Choose Your Plan
        </Typography>
        <Grid spacing={2} container justifyContent="center" alignContent="center">
          <Grid spacing={{ xs: 12, md: 6, lg: 6 }}>
            <Card sx={{ width: "320px" }}>
              <CardContent>
                <Typography variant="h5" fontWeight="bold" my={2}>
                  Starter
                </Typography>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Up to 5 surveys" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Up to 100 responses" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Basic analysis" />
                </ListItem>
                <Divider />
                <CardActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography>Free</Typography>
                  <Button variant="contained" endIcon={<KeyboardArrowRight />} onClick={navigateToAccount}>
                    Start now
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
          <Grid spacing={{ xs: 12, md: 6, lg: 6 }}>
            <Card sx={{ width: "320px" }}>
              <CardContent>
                <Typography variant="h5" fontWeight="bold" my={2}>
                  Professional
                </Typography>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Up to 10 surveys" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Up to 1000 responses" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText primary="Advance analysis" />
                </ListItem>
                <Divider />
                <CardActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography>Comming soon</Typography>
                  <Button variant="contained" endIcon={<KeyboardArrowRight />} onClick={navigateToAccount} disabled>
                    Start now
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PricingSection;
