import { Survey } from "../../types/survey";

export const ROUTE = {
  ANSWER: "/answer",
  USER_ME: "/user/me",
  ACCOUNT: "/account",
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signup",
  SURVEY: "/survey",
  REPORT: "/report",
  DASHBOARD: "/dashboard",
  USER: "/user",
  SETTING: "/setting",
  VERIFY_EMAIL: "/verify-email",
  QUESTION: "question",
  HOWTO: "/how-to",
  ACTIVITY: "/activity",
};

export const SURVEY_TYPE = {
  NPS: "nps",
  CSAT: "csat",
  TEXT: "text",
  FINISH: "finish",
};

export const ACTIVITY_TYPE = {
  ANSWERED: "answered",
  SUBMITTED: "submitted",
};

export const PROTERATE_TOKEN = "proterateToken";
export const PROTERATE = "proterate";
export const PROTERATE_UUID = "proterateUUID";
export const PROTERATE_REFRESH_TOKEN = "proterateRefreshToken";
export const PROTERATE_ACCEPT_COOKIE = "proterateAcceptCookie";

export const SURVEY_DEFAULT: Survey = {
  _id: "",
  creatorId: {
    _id: "",
    name: "",
    email: "",
    role: "",
  },
  isActive: true,
  title: "",
  description: "",
  triggerCriteria: [
    {
      hookUrl: "",
      pattern: "",
    },
  ],
  question: [
    {
      questionId: [
        {
          _id: "",
          questionDisplay: "",
          isRequire: false,
          option: {
            optionChoices: [""],
            optionType: "",
            _id: "",
          },
        },
      ],
      step: 0,
    },
  ],
  displayOption: "1",
};
