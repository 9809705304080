import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSurvey } from "../../../../utils/apis/survey-api";
import { useSnackbar } from "../../../../libs/snackbar-provider";

export const useUpdateSurvey = () => {
  const queryClient = useQueryClient();
  const showSnackbar = useSnackbar();
  const { mutate: mutateSurvey, isPending: isSurveyLoading } = useMutation({
    mutationFn: updateSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["survey"] });
      showSnackbar("The survey has been successfully updated.", "success");
    },
    onError: (error) => {
      showSnackbar(error.message || "Something went wrong, please try again later", "error");
    },
  });

  return { mutateSurvey, isSurveyLoading };
};
