import { Box, Typography, Container, Link } from "@mui/material";

import Footer from "../home/components/Footer";

export const CookiePolicy = () => {
  return (
    <Container>
      <Box display="flex" justifyContent="space-between" position="sticky" py={2}>
        <Typography
          variant="h4"
          noWrap
          component="a"
          href="/"
          sx={{
            letterSpacing: ".3rem",
            fontFamily: "monospace",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}>
          Proterate
        </Typography>
      </Box>
      <Box mt={5} mb={3}>
        <Typography variant="h4" gutterBottom>
          Cookie Policy
        </Typography>
        <Typography variant="body1" component={"p"}>
          **Effective Date:** 11 Mar 2025
        </Typography>
        <Typography variant="body1" component={"p"}>
          Proterate ("we," "our," or "us") values your privacy and is committed to transparency about how we use cookies
          and similar technologies on our website. This Cookie Policy explains what cookies are, how we use them, and
          how you can manage your preferences.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. What Are Cookies?
        </Typography>
        <Typography variant="body1" component={"p"}>
          Cookies are small text files stored on your device when you visit a website. They help websites function
          properly, enhance user experience, and collect information about website usage.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Types of Cookies We Use
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <b>Essential Cookies</b> – Required for website functionality, such as authentication and security.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Performance Cookies</b> – Help us analyze website traffic and improve user experience (e.g., Google
              Analytics).
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Functional Cookies</b> – Store user preferences like language and theme settings.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>Advertising Cookies</b> – Used to display relevant ads and track marketing performance.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" gutterBottom>
          3. Managing Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          You can manage or disable cookies through your browser settings:
        </Typography>
        <ul>
          <li>
            <Link href="chrome://settings/cookies" target="_blank">
              Google Chrome
            </Link>
          </li>
          <li>
            <Link href="about:preferences#privacy" target="_blank">
              Mozilla Firefox
            </Link>
          </li>
          <li>
            <Link href="safari://preferences/privacy" target="_blank">
              Safari
            </Link>
          </li>
          <li>
            <Link href="edge://settings/content/cookies" target="_blank">
              Microsoft Edge
            </Link>
          </li>
        </ul>

        <Typography variant="h5" gutterBottom>
          4. Contact Us
        </Typography>
        <Typography variant="body1">
          If you have any questions about our use of cookies, please contact us at:
        </Typography>
        {/* <Typography variant="body1">
          📧 <Link href="mailto:your@email.com">your@email.com</Link>
        </Typography> */}
      </Box>
      <Footer />
    </Container>
  );
};
