import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid2 as Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";

type AddNewQuestionDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleCreateNewQuesetion: (question: string, questiontype: string) => void;
};

export const AddNewQuestionDialog: React.FC<AddNewQuestionDialogProps> = ({
  open,
  handleCreateNewQuesetion,
  handleClose,
}) => {
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("text");

  const handleAddNewQuestion = () => {
    handleCreateNewQuesetion(question, questionType);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Question</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid size={12}>
            <TextField
              fullWidth
              label="Question"
              variant="outlined"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="question-type">Question Type</InputLabel>
              <Select
                labelId="question-type"
                value={questionType}
                onChange={(e) => setQuestionType(e.target.value as string)}
                label="Question Type">
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="csat">CSAT</MenuItem>
                <MenuItem value="nps">NPS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAddNewQuestion} disabled={!question.trim()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
