import axiosInstance from "../axios-instance";
import { ROUTE } from "./constant";

export const saveAnswer = async (
  surveyId: string,
  userId: string,
  questionId: string,
  answerValue: string | number | null
) => {
  await axiosInstance.post(ROUTE.ANSWER, {
    surveyId: surveyId,
    uid: userId,
    questionId: questionId,
    answerValue: answerValue,
  });
};

export const saveResponse = async (responseId: string, answers: any[]) => {
  await axiosInstance.patch("/response/" + responseId, {
    responses: answers,
  });
};
