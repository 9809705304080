import { Avatar, Box, Button, Card, CardContent, Grid2 as Grid, Grow, Snackbar, TextField } from "@mui/material";
import { stringAvatar } from "../../../../utils/function/avatar";
import { useEffect, useState } from "react";
import { getUserApi, patchUserApi } from "../../../../utils/apis/user-api";
import { User } from "../../../../types/auth";

export const UserSetting = () => {
  const [user, setUser] = useState<User>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      const userRes = await getUserApi();
      setUser(userRes.data);
    };

    getUserData();
  }, []);

  const handleUserUpdate = async () => {
    if (user) {
      setSaving(true);
      const updatedUser = await patchUserApi(user);
      setUser(updatedUser);
    }
  };

  const handleChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const value = e.target.value.trim();

    setUser((prev) => (prev ? { ...prev, [key]: value } : prev));
  };

  const handleCloseSaving = () => {
    setSaving(false);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={saving}
        onClose={handleCloseSaving}
        autoHideDuration={1000}
        TransitionComponent={Grow}>
        <Box textAlign="center">Saving</Box>
      </Snackbar>
      <Grid spacing={2} container justifyContent="center" alignContent="center">
        <Grid size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
          <Card variant="outlined" sx={{ borderRadius: "15px" }}>
            <CardContent>
              <Box my={2} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                <Avatar
                  src={user?.photoUrl || undefined}
                  {...stringAvatar(`${user?.firstName} ${user?.lastName}` || "", 100, 100)}
                />
              </Box>
              <Box my={2}>
                <TextField label="Workspace ID" value={user?.uid || ""} fullWidth required disabled />
              </Box>
              <Box my={2}>
                <TextField label="Email" value={user?.email || ""} fullWidth required disabled />
              </Box>
              <Box my={2}>
                <TextField
                  label="First name"
                  name="firstName"
                  value={user?.firstName || ""}
                  fullWidth
                  required
                  onChange={handleChangeName}
                />
              </Box>
              <Box my={2}>
                <TextField
                  label="Last name"
                  name="lastName"
                  value={user?.lastName || ""}
                  fullWidth
                  required
                  onChange={handleChangeName}
                />
              </Box>
              <Box my={2} textAlign="end">
                <Button variant="contained" onClick={handleUserUpdate}>
                  Save
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
