import React, { useCallback, useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { Survey } from "../../types/survey";
import axiosInstance from "../../utils/axios-instance";

import "./survey.scss";
import { PROTERATE, PROTERATE_UUID } from "../../utils/apis/constant";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  Grid2,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { saveActivity } from "../../utils/apis/activity-api";
import { Display } from "./components/display";
import { saveAnswer, saveResponse } from "../../utils/apis/response-api";

export const SurveyWidget = () => {
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([PROTERATE, PROTERATE_UUID]);
  // TODO : Change the defaultOpen to calculate from api
  const defaultOpen = cookies.proterate?.["responsed"] || false;
  const [open, setOpen] = useState(!defaultOpen);
  const [survey, setSurvey] = useState<Survey>();
  const [surveyComplete, setSurveyComplete] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers] = useState<any>([]);

  const handleOnSurveyClose = useCallback(async () => {
    setOpen(false);
    const originUrl = window.parent.location.href;
    await saveActivity(survey?._id || "", cookies.proterateUUID, "closed", { url: originUrl });
    window.parent.postMessage("closeSurvey", "*");
  }, [cookies.proterateUUID, survey?._id]);

  const onSurveySubmit = async (event: React.SyntheticEvent, value: number | string | null) => {
    event.preventDefault();
    const originUrl = window.parent.location.href;
    if (survey?.question && currentQuestion === survey.question.length - 1) {
      setSurveyComplete(true);
    }

    const responseId = cookies.proterate["responseId"];
    const uid = cookies.proterateUUID;
    answers.push({ questionId: survey?.question?.[currentQuestion].questionId[0]._id, responseValue: value });
    await saveActivity(survey?._id || "", cookies.proterateUUID, "answered", {
      url: originUrl,
      response: { questionId: survey?.question?.[currentQuestion].questionId[0]._id, responseValue: value },
    });
    await saveAnswer(
      survey?._id || "default-survey-id",
      uid || "default-id",
      survey?.question?.[currentQuestion].questionId[0]._id || "",
      value || ""
    );

    if (currentQuestion < (survey?.question?.length || 1) - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      await saveActivity(survey?._id || "", cookies.proterateUUID || "", "submitted", {
        url: originUrl,
        response: answers,
      });
      await saveResponse(responseId, answers);
      setCookie(PROTERATE, { responseId: responseId, responsed: true }, { path: "/" });
      setTimeout(() => {
        window.parent.postMessage("closeSurvey", "*");
      }, 1000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const workspaceId = searchParams.get("workspace_id");
      const originUrl = window.parent.location.href;
      const uid = cookies.proterateUUID;
      const result = await axiosInstance.post<Survey>("/survey/" + workspaceId, { url: originUrl, userId: uid });
      if (!result) {
        setOpen(false);
      }

      const surveyResponse = result.data;

      if (!cookies.proterateUUID) {
        setCookie("proterateUUID", crypto.randomUUID(), { path: "/" });
      }

      if (surveyResponse._id) {
        if (!surveyResponse.isActive) {
          setOpen(false);
        }

        const surveyMode = surveyResponse.displayOption === "2" ? "openSurveyWidget" : "openSurveyFullScreen";
        window.parent.postMessage(surveyMode, "*");
        setSurvey(surveyResponse);
        await saveActivity(surveyResponse?._id || "", cookies.proterateUUID || "", "displayed", { url: originUrl });
        if (!cookies.proterate) {
          const response = await axiosInstance.post("/response", {
            surveyId: surveyResponse._id,
            uid: cookies.proterateUUID,
          });
          setCookie("proterate", { responseId: (response.data as { _id: string })["_id"] }, { path: "/" });
        }
      }
    };

    // TODO: Change the condition to check if the survey is already responsed
    if (cookies.proterate?.["responsed"]) {
      window.parent.postMessage("closeSurvey", "*");
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (survey?.displayOption === "1") {
    return (
      <>
        <Dialog
          open={open}
          onClose={handleOnSurveyClose}
          id="survey-container"
          className="survey-variant-1-desktop-container">
          <IconButton
            aria-label="close"
            onClick={handleOnSurveyClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}>
            <CloseIcon />
          </IconButton>
          <DialogContent className="survey-variant-1-desktop-container-content">
            {survey && (
              <Display
                survey={survey}
                currentQuestion={currentQuestion}
                onSurveySubmit={onSurveySubmit}
                surveyComplete={surveyComplete}
              />
            )}
          </DialogContent>
        </Dialog>

        <Box className="survey-variant-1-mobile-container">
          <Backdrop open></Backdrop>
          <Card className="nps-survey-card" variant="outlined">
            <>
              <IconButton
                aria-label="close"
                onClick={handleOnSurveyClose}
                sx={(theme) => ({
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                })}>
                <CloseIcon />
              </IconButton>
              <CardContent>
                <Grid2 spacing={0} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                  {survey && (
                    <Display
                      survey={survey}
                      currentQuestion={currentQuestion}
                      onSurveySubmit={onSurveySubmit}
                      surveyComplete={surveyComplete}
                    />
                  )}
                </Grid2>
              </CardContent>
            </>
          </Card>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box className="nps-survey-container">
        <Card className="nps-survey-card" variant="outlined" sx={{ position: "relative" }}>
          <LinearProgress
            variant="determinate"
            value={(100 / (survey?.question?.length || 1)) * (currentQuestion + 1)}
            sx={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
          <Box sx={{ position: "absolute", right: 8, top: 8 }}>
            <IconButton onClick={handleOnSurveyClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CardContent>
            {survey && (
              <Display
                survey={survey}
                currentQuestion={currentQuestion}
                onSurveySubmit={onSurveySubmit}
                surveyComplete={surveyComplete}
              />
            )}
            {currentQuestion === 0 && (
              <Box>
                <Divider />
                <Typography variant="body2" component={"div"} mt={1}>
                  We use cookies to improve your experience. By continuing, you agree to our{" "}
                  <a href="/cookie-policy" target="_blank">
                    Cookie Policy
                  </a>
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
