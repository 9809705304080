export function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(
  name: string,
  width?: number,
  height?: number
): { sx: { bgcolor: string; width?: number; height?: number }; children: string } {
  const displayName = `${name.split(" ")?.[0]?.[0] || ""}${name.split(" ")?.[1]?.[0] || ""}`;
  return {
    sx: {
      bgcolor: stringToColor(name),
      width,
      height,
    },
    children: displayName,
  };
}
