import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AuthLayout } from "../../../../components/layouts/auth-layout";
import { auth } from "../../../../config/firebase";
import { postCreateUser } from "../../../../utils/apis/user-api";
import { PROTERATE_TOKEN, ROUTE } from "../../../../utils/apis/constant";
import { useLoginForm } from "./use-login-form";

export const Login = () => {
  const setCookie = useCookies([PROTERATE_TOKEN])[1];
  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleSignLoading, setIsGoogleSignLoading] = useState(false);
  const navigation = useNavigate();

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const { onSubmit, register, handleSubmit, errors, isPending, isError, apiError } = useLoginForm();

  const mutationCreateUser = useMutation({
    mutationFn: postCreateUser,
    onSuccess: async () => {
      navigation(ROUTE.DASHBOARD);
    },
    onError() {
      setIsGoogleSignLoading(false);
    },
  });

  const onGoogleLogin = async () => {
    setIsGoogleSignLoading(true);
    const googleProvider = new GoogleAuthProvider();
    const googleUser = await signInWithPopup(auth, googleProvider);
    const fullName = googleUser.user.displayName?.split(" ");

    const token = await googleUser.user.getIdToken();
    setCookie(PROTERATE_TOKEN, token, { path: "/" });

    mutationCreateUser.mutate({
      firstName: fullName?.[0] || undefined,
      lastName: fullName?.[1] || undefined,
      email: googleUser.user.email || "",
      password: undefined,
      role: "admin",
      provider: "google.com",
      providerUserId: googleUser.user.uid,
    });
  };

  return (
    <AuthLayout>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: "100%",
          maxWidth: 600,
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}>
        <Typography variant="h5" textAlign="center" mb={2}>
          Login
        </Typography>
        <FormControl fullWidth error={!!errors.email} variant="outlined">
          <InputLabel htmlFor="email-input">email</InputLabel>
          <OutlinedInput label="email" id="email-input" type={"text"} {...register("email")} />
          {errors.email && <FormHelperText error>{errors.email?.message}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth variant="outlined" error={!!errors.password}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            label="password"
            id="password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={showPassword ? "hide the password" : "display the password"}
                  onClick={toggleShowPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            {...register("password")}
          />
          {errors.password && <FormHelperText error>{errors.password?.message}</FormHelperText>}
        </FormControl>
        {isError && <Alert severity="error">{apiError?.message || "Something went wrong."}</Alert>}
        <Button type="submit" variant="outlined" color="primary" fullWidth size="large" loading={isPending}>
          Login
        </Button>
        {mutationCreateUser.isError && (
          <Alert severity="error">{mutationCreateUser.error.message || "Something went wrong."}</Alert>
        )}
        <Divider>OR</Divider>
        <Button
          loading={mutationCreateUser.isPending || isGoogleSignLoading}
          disabled={mutationCreateUser.isPending || isGoogleSignLoading}
          variant="outlined"
          size="large"
          fullWidth
          startIcon={<img src="/assets/logo_google.png" alt="google" width={24} />}
          onClick={onGoogleLogin}
          loadingPosition="end">
          Sign in with google
        </Button>
        <Box sx={{ textAlign: "end" }}>
          <Link to={ROUTE.SIGNUP}>Don't have an account</Link>
        </Box>
      </Box>
    </AuthLayout>
  );
};
