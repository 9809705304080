import { CreateQuestionRequest, Survey, SurveyDto, DeleteQuestionRequest } from "../../types/survey";
import axiosInstance from "../axios-instance";
import { ROUTE } from "./constant";
import { SurveyByIdRequest, SurveyStatusByIdRequest } from "./types/survey.types";

export const getSurveysByUserId = async (userId: string): Promise<SurveyDto> => {
  const res = await axiosInstance.get<SurveyDto>("/survey/" + userId);
  const surveys = res.data;
  return surveys;
};

export const saveSurvey = async (survey: Survey, userId: string): Promise<Survey> => {
  const surveyRes = await axiosInstance.post<Survey>(ROUTE.SURVEY, {
    ...survey,
    creatorId: userId,
    triggerCriteria: [{ hookUrl: "", pattern: "exact" }],
  });
  if (surveyRes.status === 201) {
    await axiosInstance.post("question", {
      surveyId: surveyRes.data._id,
      questionDisplay: "What is your feeling?",
      isRequired: true,
      option: {
        optionType: "nps",
      },
      step: 1,
    });
  }

  return surveyRes.data;
};

export const getSurveyToken = async (token?: string): Promise<Survey> => {
  const survey = await axiosInstance.get<Survey>("/survey/token/" + token);
  return survey.data;
};

export const createSurvey = async (userId: string, surveyType: string): Promise<Survey> => {
  const surveyRes = await axiosInstance.post<Survey>(ROUTE.SURVEY, { creatorId: userId, surveyType });
  if (surveyRes.status === 201) {
    await axiosInstance.post("question", {
      surveyId: surveyRes.data._id,
      questionDisplay: "What is your feeling?",
      isRequired: true,
      option: {
        optionType: "csat",
      },
      step: 1,
    });
  }

  return surveyRes.data;
};

export const createQuestion = async (question: CreateQuestionRequest): Promise<boolean> => {
  const result = await axiosInstance.post(ROUTE.QUESTION, {
    surveyId: question.surveyId,
    questionDisplay: question.question || "default",
    isRequired: true,
    option: {
      optionType: question.questionType || "text",
    },
    step: question.step,
  });

  return result.status === 201 ? true : false;
};

export const deleteQuestion = async (question: DeleteQuestionRequest): Promise<any> => {
  const result = await axiosInstance.delete(ROUTE.QUESTION + "/" + question.surveyId + "/" + question.questionId);

  return result.data;
};

export const getSurveyBySurveyId = async (req: SurveyByIdRequest): Promise<Survey> => {
  const survey = await axiosInstance.get<Survey>("/survey/id/" + req.surveyId);
  return survey.data;
};

export const updateSurveyStatus = async (req: SurveyStatusByIdRequest): Promise<Survey> => {
  const survey = await axiosInstance.patch<Survey>("/survey/" + req.surveyId + "/toggle-status", {
    isActive: req.status,
  });
  return survey.data;
};

export const updateSurvey = async (survey: Survey): Promise<Survey> => {
  const res = await axiosInstance.patch<Survey>("/survey/" + survey._id, survey);
  if (res.status === 200) {
    return survey;
  }
  return survey;
};
