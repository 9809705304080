import { AccumurateDto, Report, SurveyStatDto } from "../../types/report";
import axiosInstance from "../axios-instance";

export const getReport = async (surveyId?: string, page: number = 1, limit: number = 10): Promise<Report> => {
  const report = await axiosInstance.get<Report>(`/response/${surveyId}?page=${page}&limit=${limit}`);
  return report.data;
};

export const getAccumurate = async (surveyId?: string): Promise<AccumurateDto> => {
  const report = await axiosInstance.get<AccumurateDto>("/response/accumurate/" + surveyId);
  return report.data;
};

export const getSurveyStat = async (surveyId?: string): Promise<SurveyStatDto[]> => {
  const report = await axiosInstance.get<SurveyStatDto[]>("/response/stat/" + surveyId);
  return report.data;
};
