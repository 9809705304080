import { Box, Grid2 as Grid, Rating, Typography } from "@mui/material";
import { IconContainerProps } from "@mui/material/Rating";
import { customIcons } from "./icon";

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

type CastOptionProps = {
  onSurveySubmit: (event: React.SyntheticEvent, value: number | null) => void;
};

export const CastOption: React.FC<CastOptionProps> = ({ onSurveySubmit }) => {
  return (
    <>
      <Grid spacing={2} size={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box sx={{ justifyContent: "center", display: "flex", flexDirection: "row" }}>
          <Rating
            name="highlight-selected-only"
            defaultValue={2}
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            sx={{ gap: "20px", fontSize: "25px" }}
            onChange={onSurveySubmit}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          my="10px"
          maxWidth={"300px"}>
          <Typography variant="body1">Terrible</Typography>
          <Typography variant="body1">Excellent</Typography>
        </Box>
      </Grid>
    </>
  );
};
