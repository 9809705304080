import { Box, Button, ButtonGroup, Grid2 as Grid, Typography } from "@mui/material";
import { Question } from "../../../types/survey";

type NpsOptionProps = {
  question: Question;
  onSurveySubmit: (event: React.SyntheticEvent, value: number | null) => void;
};

export const NpsOption: React.FC<NpsOptionProps> = ({ question, onSurveySubmit }) => {
  return (
    <>
      <Grid spacing={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <ButtonGroup variant="outlined" aria-label="nps-rating" size="small">
          {[...Array(10)].map((_, index) => (
            <Button
              key={index + 1}
              variant="outlined"
              onClick={(e) => onSurveySubmit(e, index + 1)}
              sx={{
                color: "black",
                borderColor: "black",
                "&.Mui-contained": { backgroundColor: "black" },
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.8)", color: "white" },
              }}>
              {index + 1}
            </Button>
          ))}
        </ButtonGroup>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" my="10px">
          <Typography variant="body1">Terrible</Typography>
          <Typography variant="body1">Excellent</Typography>
        </Box>
      </Grid>
    </>
  );
};
