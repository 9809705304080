import axiosInstance from "../axios-instance";
import { ROUTE } from "./constant";

export const saveActivity = async (
  surveyId: string,
  userId: string,
  action: string,
  metadata?: Record<string, any>
): Promise<any> => {
  try {
    const activityRes = await axiosInstance.post(ROUTE.ACTIVITY, {
      surveyId: surveyId,
      userId: userId,
      action: action,
      metadata: metadata,
    });
    return activityRes.data;
  } catch (error) {
    console.log(error);
  }
};
