import React from "react";
import { Box, Slider, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

const marks = [
  { value: 0, icon: <SentimentVeryDissatisfiedIcon color="error" /> },
  { value: 25, icon: <SentimentDissatisfiedIcon color="warning" /> },
  { value: 50, icon: <SentimentNeutralIcon color="secondary" /> },
  { value: 75, icon: <SentimentSatisfiedIcon color="success" /> },
  { value: 100, icon: <SentimentVerySatisfiedIcon color="primary" /> },
];

type CSATMeterProps = {
  value: number;
};

export const CSATMeter: React.FC<CSATMeterProps> = ({ value }) => {
  return (
    <>
      <Box sx={{ width: 400, textAlign: "center", mt: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          {marks.map((mark, index) => (
            <Box key={index} sx={{ textAlign: "center" }}>
              {mark.icon}
            </Box>
          ))}
        </Box>
        <Slider
          value={value}
          step={25}
          marks
          min={0}
          max={100}
          sx={{
            color: value <= 25 ? "#d32f2f" : value <= 50 ? "#f57c00" : value <= 75 ? "#388e3c" : "#1976d2",
          }}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" my="10px">
          <Typography variant="body1">Terrible</Typography>
          <Typography variant="body1">Excellent</Typography>
        </Box>
      </Box>
    </>
  );
};
